import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection } from "../../styledComponents/article"
import { FadingBackground } from "../../components/modalFadingBackground"
import {
  Section,
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Modal from "../../components/modal"  
import AllServicesList from "../../components/servicesList"

const ServicesArchive = ({ data, location, createCookie }) => {

    return (
      <Layout location={location}>
        <Seo 
          title="Our Services - we're here to help your business online" 
          description="We are passsionate about web technology and making websites as user friendly and optimised for the best experience possible."
          image=""
          url="/services"
        />
          <Section background="var(--mid-blue-mid)" type="pullout-with-image">
                <h2>Our Services - we're here to help your business online</h2>
                <svg xmlns="http://www.w3.org/2000/svg" width="298" height="218">
                    <g fill="none" fillRule="evenodd">
                        <ellipse cx="149" cy="109" fill="#FFF" rx="149" ry="109" />
                        <g fillRule="nonzero" transform="translate(44 50)">
                            <path fill="#2F2E41" d="M81.127 13.236C79.512 7.229 92.54 2.077 95.96 6.915c.476-.688 2.234-1.106 3.04-.895.807.211 1.486.739 2.147 1.25.91.712 1.854 1.45 2.412 2.476.562 1.021.65 2.414-.108 3.3-.6.705-2.37 1.16-3.272 1.346-.628.13-1.158.236-1.613.312a3.194 3.194 0 0 1-.537-2.08 2.563 2.563 0 0 0-1.092 1.873c-.013.156 5.083 19.284 5.083 19.284-.525.316-1.175.612-1.923.882-1.27-1.954-2.312-4.258-3-6.667-.156 1.49-.131 3.105.059 4.747.1.873.248 1.75.438 2.628-6.055 1.334-15.164.895-19.126-4.776l-.002-.07c-.029-1.062-1.448-1.325-1.828-.337-.096.25-.185.467-.263.639-1.155-4.211.339-9.085 4.752-17.591Z" />
                            <path fill="#FFB6B6" d="M163.031 58.766a4.15 4.15 0 0 1-6.304.03L137.06 65 134 60.003l23.413-7.199a4.144 4.144 0 0 1 5.78.869 4.104 4.104 0 0 1-.162 5.093Z" />
                            <path fill="#008AAA" d="M95 44.789s8.645-9.979 12.41-5.74c3.766 4.239 15.514 22.813 15.514 22.813l27.424-5.938L153 63.352l-31.139 10.355c-2.34.779-4.906-.029-6.42-2.02L95 44.789Z" />
                            <path fill="#2F2E41" d="M113 131H71.476l-.038-.328-.024-.22-.357-3.063-.505-4.364-.195-1.678c-.013-.084-.027-.166-.038-.251a26.916 26.916 0 0 1-.302-3.283 31.87 31.87 0 0 1 .176-4.49v-.008a41.614 41.614 0 0 1 .956-5.354c.275-1.128.589-2.225.932-3.284a53.14 53.14 0 0 1 1.415-3.907l.003-.003C75.85 94.964 78.52 91 78.52 91h30.25s1.4 6.219 2.094 11.354c.11.813.203 1.595.27 2.323.122 1.344.157 2.498.052 3.283-.065.487-.184.832-.368.99-1.664 1.44.24 4.493.24 4.493s1.427 2.208.184 3.45c-.245.247-.386.564-.454.92-.108.588-.013 1.272.136 1.876a9.786 9.786 0 0 0 .55 1.596l.211 1.688.206 1.625.348 2.79.106.493.543 2.572.045.219.068.328Z" />
                            <path fill="#008AAA" d="M91.979 31 78.95 32.153l-2.4 7.428-2.618.377c-4.471.644-7.133 5.306-5.395 9.452l2.302 5.494s-3.382 10.377.772 14.737l1.767 19.204-3.987 12.66L116 103l-7.94-15.88-.235-9.859s5.074-17.821.37-23.49L106.36 37.65l-9.561-1.426L91.979 31Z" />
                            <ellipse cx="90.752" cy="19.604" fill="#FFB6B6" rx="10.671" ry="10.692" transform="rotate(-76.947 90.752 19.604)" />
                            <path fill="#2F2E41" d="M75.127 14.236C73.512 8.229 86.54 3.077 89.96 7.915c.476-.688 2.234-1.106 3.04-.895.807.211 1.486.739 2.147 1.25.91.712 1.854 1.45 2.412 2.476.562 1.021.65 2.414-.108 3.3-.6.705-2.37 1.16-3.272 1.346-.628.13-1.158.236-1.613.312a3.194 3.194 0 0 1-.537-2.08 2.563 2.563 0 0 0-1.092 1.873c-.013.156 5.083 19.284 5.083 19.284-.525.316-1.175.612-1.923.882-1.27-1.954-2.312-4.258-3-6.667-.156 1.49-.131 3.105.059 4.747.1.873.248 1.75.438 2.628-6.055 1.334-15.164.895-19.126-4.776l-.002-.07c-.029-1.062-1.448-1.325-1.828-.337-.096.25-.185.467-.263.639-1.155-4.211.339-9.085 4.752-17.591Z" />
                            <path fill="#3F3D56" d="M166 131.706H22.299L0 132l156.382-9.982c5.217-.333 9.618 4.1 9.618 9.688Z" />
                            <path fill="#008AAA" d="M178.495 19h-35.99c-.83 0-1.505-.673-1.505-1.5s.675-1.5 1.505-1.5h35.99c.83 0 1.505.673 1.505 1.5s-.675 1.5-1.505 1.5Z" />
                            <path fill="#3F3D56" d="M160.895 23.26a.431.431 0 0 0-.791 0c-1.012 2.411-2.452 5.113-4.019 7.177-.25.33.095.772.494.633l3.358-1.175V50h1.125V29.895l3.36 1.175c.398.14.744-.302.493-.633-1.567-2.064-3.008-4.766-4.02-7.178Z" />
                            <path fill="#F2F2F2" d="M213.035 30h-23.07c-.532 0-.965-.449-.965-1 0-.552.433-1 .965-1h23.07c.532 0 .965.448.965 1 0 .551-.433 1-.965 1Zm-12.315 3.163c-.081-.217-.36-.217-.44 0-.562 1.519-1.362 3.22-2.233 4.52-.14.208.053.486.274.398l1.866-.74V50h.625V37.341l1.867.74c.221.088.413-.19.274-.398-.87-1.3-1.671-3.001-2.234-4.52Z" />
                            <circle cx="201" cy="55" r="5" fill="#F2F2F2" />
                            <path fill="#F2F2F2" d="M140.035 2h-23.07c-.532 0-.965-.449-.965-1 0-.552.433-1 .965-1h23.07c.532 0 .965.448.965 1 0 .551-.433 1-.965 1ZM127.72 4.163c-.081-.217-.36-.217-.44 0-.562 1.519-1.362 3.22-2.233 4.52-.14.208.053.486.274.398l1.866-.74V21h.625V8.341l1.867.74c.221.088.413-.19.274-.398-.87-1.3-1.671-3.001-2.234-4.52Z" />
                            <circle cx="128" cy="28" r="5" fill="#F2F2F2" />
                            <path fill="#FFB6B6" d="M72.385 123.002c1.217-1.965.571-4.519-1.444-5.706a4.307 4.307 0 0 0-.61-.297l-.428-20.706L64 95l.692 24.59c-.715 2.182.522 4.517 2.76 5.213 1.875.583 3.914-.162 4.933-1.801Z" />
                            <path fill="#008AAA" d="M76.912 43.284s-7.017-1.66-9.263 3.043c-2.245 4.703-2.437 25.169-2.437 25.169L64 113l8.901-.387L80 61.543l-3.088-18.259Z" />
                            <path fill="#CCC" d="M73.589 127.685a.832.832 0 0 0 .648.303h45.368a.824.824 0 0 0 .834-.815.79.79 0 0 0-.018-.166l-.704-3.26a.822.822 0 0 0-.52-.592.827.827 0 0 0-.296-.054H74.939a.827.827 0 0 0-.297.054.822.822 0 0 0-.52.591l-.704 3.261a.8.8 0 0 0 .17.678Z" />
                            <path fill="#E6E6E6" d="M56.22 54.693v51.01c.001 1.353 1.127 2.45 2.516 2.451h77.55c1.389 0 2.514-1.098 2.515-2.451v-51.01c-.003-1.353-1.128-2.448-2.515-2.45h-77.55c-1.388.002-2.512 1.097-2.516 2.45Z" />
                            <path fill="#CCC" d="M107.208 97.246a.933.933 0 0 0-.287-.044H88.778c-.534 0-.97.423-.97.943v27.937h20.08V98.145a.944.944 0 0 0-.68-.9Zm-7.277 13.74c-.005 1.155-.97 2.086-2.154 2.081-1.177-.008-2.127-.934-2.133-2.081v-3.242c.012-1.152.981-2.078 2.163-2.067 1.169.01 2.113.931 2.124 2.067v3.242Z" />
                        </g>
                    </g>
                </svg>
            </Section>

            <Article
                itemScope
                itemType="http://schema.org/Article"
                servicesList
            >
                <ArticleSection isServicesList>
                    <AllServicesList isHomePage={false}/>
                </ArticleSection>
            </Article>

            <HeroTextImageWrapper 
              backgroundColor="var(--orange-mid)" 
            >
              <StaticImage
                layout="fullWidth"
                src={
                  "../../images/contact-us-lets-talk.jpg"
                }
                formats={["auto", "webp", "avif"]}
                className="padding1"
              />
              <HeroText fontColor="var(--white)">
                  <HeroHeader level={1}>Let's talk</HeroHeader>
                  <HeroDescription>Conact us today to see how we can help your business online.</HeroDescription>
                  <ModalProvider backgroundComponent={FadingBackground}>
                    <Modal 
                      title="Contact Us" 
                      type="hero" 
                      serviceName="Website Design Optimisation"
                      buttonLabel="Say hello 👋"
                      buttonStyle="HeroButton"
                      formExcerpt="Please fill in the following form and one of our team will get back to you within 24 hours."
                    />
                  </ModalProvider>
              </HeroText>
            </HeroTextImageWrapper>
      </Layout>
    )
}

export default ServicesArchive